import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NotFoundPage from './pages/not-found';
import SignInPage from './pages/sign-in';
import PiracyPolicyPage from './pages/piracy-policy';
import usePages from './hooks/usePages.tsx';
import { useEffect, useState } from 'react';
import useUserAccess from './hooks/useUserAccess.tsx';
import { Auth } from 'aws-amplify';
import NoAccessPage from './pages/no-access';
import PageLoader from './components/_pages/PageLoader';
import Page from './components/_pages/Page';
import {BooleanParam, useQueryParam} from "use-query-params";

const PrivateRoute = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { userAccess, isLoading: isLoadingAccess } = useUserAccess();
    const [isPasswordRequired] = useQueryParam('isPasswordRequired', BooleanParam);

    const authenticate = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsUserLoaded(true);
            setIsAuthenticated(true);
        } catch (error) {
            setIsAuthenticated(false);
            const urlObj = new URL(window.location.origin);
            if (isPasswordRequired) urlObj.searchParams.append('isPasswordRequired', `1`);
            navigate(`/sign-in${urlObj.search}`);
        }
    };

    useEffect(() => {
        (async () => {
            await authenticate();
        })();
    }, [location]);

    if (!isUserLoaded || userAccess === undefined) return <PageLoader isLoading />;

    if (!isLoadingAccess && !userAccess?.length && location.pathname !== '/sign-in') return <NoAccessPage />;

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isAuthenticated ? <Outlet /> : <Navigate to="/sign-in" />;
};

const AppRoutes = () => {
    const { data: pages } = usePages();

    return (
        <Routes>
            <Route path="/sign-in" element={<SignInPage />} />
            <Route element={<PrivateRoute />}>
                <Route path="/piracy-policy" element={<PiracyPolicyPage />} />
                {pages?.map((page) => <Route path={page.path} element={<Page page={page} />} key={page?.SK} />)}
                <Route path="*" element={<NotFoundPage />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
