import { useEffect, useState } from 'react';
import { useCreatorConfig } from '../../providers/ConfigProvider';
import { useAuth } from '../../providers/AuthProvider';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LanguageSelector from '../../components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import usePages from '../../hooks/usePages.tsx';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import SignInIssuesModal from '../../components/SignInIssuesModal';
import Button from '../../components/_base/Button';
import PrimaryText from '../../components/_base/PrimaryText';
import { BooleanParam, useQueryParam } from 'use-query-params';

const REMEMBER_EMAIL = 'REMEMBER_EMAIL';
const REMEMBER_ME = 'REMEMBER_ME';
const SignInPage = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { vendorUuid, brandName, theme, marketingSiteUrl } = useCreatorConfig();
    const [isSignInIssueModalOpen, setIsSignInIssueModalOpen] = useState(false);

    const { signInPasswordless, signIn, verifySignInCode } = useAuth();
    const [isPasswordRequired] = useQueryParam('isPasswordRequired', BooleanParam);
    const navigate = useNavigate();

    const [email, setEmail] = useState((searchParams.get('email') || localStorage.getItem(REMEMBER_EMAIL)) ?? '');
    const isValidEmail = email && email.includes('@') && email.includes('.');
    const [password, setPassword] = useState('');

    const [rememberMe, setRememberMe] = useState(localStorage.getItem(REMEMBER_ME) === 'true');
    const [code, setCode] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [unverifiedUser, setUnverifiedUser] = useState(undefined as CognitoUser | undefined);
    const { cognitoUser } = useAuth();
    const { refetch: refetchPages, homePage } = usePages();

    useEffect(() => {
        localStorage.setItem(REMEMBER_ME, rememberMe.toString());
    }, [rememberMe]);

    useEffect(() => {
        if (cognitoUser) navigate(homePage?.path || '/');
    }, [cognitoUser, homePage]);

    const onClickSignIn = async () => {
        try {
            setIsSigningIn(true);
            setErrorMessage('');
            if (unverifiedUser) {
                const user = await verifySignInCode({ unverifiedUser, code });
                if (user) {
                    await refetchPages();
                    navigate('/piracy-policy', { replace: true });
                }
            } else {
                if (rememberMe) localStorage.setItem(REMEMBER_EMAIL, email);
                let user;
                if (isPasswordRequired) {
                    user = await signIn({ email, password });
                    if (user) navigate('/piracy-policy', { replace: true });
                } else {
                    user = await signInPasswordless(email);
                    if (user) setUnverifiedUser(user);
                }
            }
        } catch (err) {
            const error = err as {
                message: string;
            };
            console.log('Something went wrong!', error);
            setErrorMessage(error.message as string);
        } finally {
            setIsSigningIn(false);
        }
    };

    return (
        <div className="flex min-h-screen flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
            <div className="w-full max-w-sm space-y-10">
                <div>
                    <img className="mx-auto w-64 h-auto" src={theme?.logos?.logoDark} alt={brandName} />
                    <PrimaryText block className="mt-10 text-center text-xl font-bold leading-9 tracking-tight">
                        {t('Sign in to your account')}
                    </PrimaryText>
                </div>
                <div className="space-y-6">
                    {errorMessage && <div className="text-red-500 text-center">{errorMessage}</div>}
                    {!unverifiedUser && (
                        <div className="relative -space-y-px rounded-md">
                            <div
                                className="rounded-3xl overflow-hidden border"
                                style={{
                                    borderColor: theme?.colours?.primaryColour,
                                }}>
                                <label htmlFor="email-address" className="sr-only">
                                    {t('Email address')}
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="relative block w-full py-1.5 sm:text-sm sm:leading-6 border-0 text-black outline text-center"
                                    placeholder={t('Email address')}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                    {!unverifiedUser && isPasswordRequired && (
                        <div className="relative -space-y-px rounded-md">
                            <div
                                className="rounded-3xl overflow-hidden border"
                                style={{
                                    borderColor: theme?.colours?.primaryColour,
                                }}>
                                <label htmlFor="email-address" className="sr-only">
                                    {t('Password')}
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="password"
                                    required
                                    className="relative block w-full py-1.5 sm:text-sm sm:leading-6 border-0 text-black outline text-center"
                                    placeholder={t('Password')}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                    {unverifiedUser && (
                        <div className="flex flex-col items-center justify-center gap-4">
                            <PrimaryText className="text-center">{`${t(
                                'Enter verification code sent to',
                            )}`}</PrimaryText>
                            <PrimaryText className="text-center font-bold">{email}</PrimaryText>
                            <OtpInput
                                inputType="tel"
                                placeholder="000000"
                                numInputs={6}
                                onChange={setCode}
                                value={code}
                                shouldAutoFocus
                                renderInput={(props) => <input {...props} />}
                                inputStyle="p-0 h-10 rounded-md font-mono m-1 border-2 border-gray-300 shadow-2xl text-center box-border text-3xl text-black"
                            />
                        </div>
                    )}

                    <div className="flex items-center  justify-center">
                        <div className="flex items-center">
                            <PrimaryText isSelected>
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300"
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.checked)}
                                />
                            </PrimaryText>
                            <PrimaryText className="ml-3 block text-sm leading-6">{t('Remember me')}</PrimaryText>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center gap-4">
                        <Button
                            isPrimary
                            disabled={!isValidEmail || isSigningIn || !vendorUuid}
                            onClick={onClickSignIn}
                            isLoading={isSigningIn || !vendorUuid}>
                            {isSigningIn ? t('Signing in...') : t('Sign In')}
                        </Button>
                        <Button size="xs" onClick={() => setIsSignInIssueModalOpen(true)}>
                            <PrimaryText className="flex flex-row items-center gap-2">
                                <ExclamationTriangleIcon className="h-4 w-4" /> Sign in issues?
                            </PrimaryText>
                        </Button>
                    </div>
                </div>

                {marketingSiteUrl && (
                    <PrimaryText block className="text-center text-sm leading-6">
                        {`${t('Not a member?')} `}
                        <a href={marketingSiteUrl} className="font-semibold hover:text-indigo-500">
                            <PrimaryText isSelected>{t('Get Access Now')}</PrimaryText>
                        </a>
                    </PrimaryText>
                )}

                <div className="flex justify-center">
                    <LanguageSelector />
                </div>
            </div>
            <SignInIssuesModal isOpen={isSignInIssueModalOpen} onClose={() => setIsSignInIssueModalOpen(false)} />
        </div>
    );
};

export default SignInPage;
